html,
body {
  overscroll-behavior-x: none;
  margin: 0;
  padding: 0;
}

.Typewriter {
  display: inline;
  &__cursor {
    display: none;
  }
  &:last {
    &__cursor {
      display: inline;
    }
  }
}
.mega-menu-scroll {
  height: auto;
}
.swiper {
  width: 100%;
  height: 100%;
}
.swiper-navigation .swiper-button.swiper-button-disabled {
  display: none;
}
.center-container .swiper-wrapper {
  @media screen and (min-width: 768px) {
    justify-content: center;
  }
}
.swiper .card-body {
  padding: 0;
}
a {
  cursor: pointer;
}
@media screen and (min-width: 992) {
  .nav-item.dropdown {
    margin-left: 10px;
  }
}
.nav-item.dropdown {
  > .nav-link {
    // color: black;
    // margin-top: 5px;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 992px) {
  .navbar-clone .nav-link {
    color: black;
    &:hover {
      color: #5eb9f0;
    }
  }
}
@media screen and (min-width: 992px) {
  footer .col-md-4:nth-child(3) .dropdown-item {
    padding: 0 !important;
    color: white;
    &:hover {
      color: #5eb9f0;
    }
    img {
      max-width: 100%;
    }
  }
  .dropdown-item,
  footer .col-md-4:last-child .dropdown-item {
    padding: 5px !important;
    color: black;
    &:hover {
      color: #5eb9f0;
    }
    img {
      max-width: 100%;
    }
  }
}

.mobile-12,
.mobile-12 div {
  text-align: left;
  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
}
.desktop {
  @media screen and (max-width: 1250px) {
    display: none;
  }
}
.tablet {
  @media screen and (max-width: 991px) {
    display: none;
  }
}
.mobile-tablet {
  @media screen and (min-width: 992px) {
    display: none;
  }
}
.font-weight-bold {
  font-weight: $font-weight-bold;
}
.text-dark {
  color: $black !important;
}
h4.smaller {
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
  }
}
.navbar-brand {
  margin-top: -6px;
}
.navbar {
  padding: 10px 0;
}
// header.bg-soft-primary {
//   position: absolute;
//   top: 0;
//   z-index: 9;
//   width: 100%;
//   // background: transparent !important;
//   // .navbar.transparent .nav-link,
//   // .navbar.transparent.navbar-light .nav-link a.dropdown-item {
//   //   color: #ddd;
//   // }
//   // .navbar.transparent.navbar-light.navbar-clone .nav-link,
//   // .navbar.transparent.navbar-light.navbar-clone .nav-link a.dropdown-item {
//   //   color: var(--bs-nav-link-color);
//   // }
// }

// .nav-tabs li.nav-item a.nav-link {
//   padding: 10px !important;
// }
.nav-tabs {
  width: 100%;
}
// .home header.bg-soft-primary .navbar {
//   background: rgb(243, 250, 254);
//   background: linear-gradient(360deg, transparent 0%, rgba(0, 0, 0, 0.6) 77%, rgba(0, 0, 0, 0.6) 100%);
// }
.home .logo {
  // filter: drop-shadow(0 0 1px #000);
}
.home .navbar-clone .logo {
  // filter: none !important;
}
.logo-text {
  font-size: 1rem;
  margin-left: 10px;
  color: #5eb9f0;
}
// .home .logo-text {
//   text-shadow: bg-;
// }
// .home header.bg-soft-primary .navbar.transparent .nav-link {
//   text-shadow: 0px 0px 2px #000000;
// }
.home.bg-soft-primary .navbar.transparent.navbar-light {
  background-color: #000;
  .nav-link {
    color: white;
    &:hover {
      color: $primary !important;
      .dropdown-item {
        color: $primary !important;
      }
    }
  }
  &.navbar-clone {
    background-color: #fff;
    .nav-link {
      color: black;
      &:hover {
        color: $primary !important;
      }
    }
  }
}
header.bg-soft-primary .navbar.transparent.navbar-light.navbar-clone .nav-link,
header.bg-soft-primary .navbar.transparent.navbar-light.navbar-clone .nav-link a.dropdown-item,
header.bg-soft-primary .navbar.transparent.navbar-light.navbar-clone .logo-text {
  text-shadow: none;
}
.offcanvas-nav ul ul li {
  list-style: none;
}
// .navbar-expand-lg .offcanvas-nav.navbar-collapse .dropdown-menu .dropdown-item {
//   padding: 0.175rem 0.175rem;
// }
// .home {
// .navbar .navbar-clone .nav-link .dropdown-item,
// .navbar .nav-link a,
// .portfolio-links {
//   color: #ddd;
// }
@media screen and (max-width: 991px) {
  .nav-link a {
    font-size: 16px !important;
  }
}
// .navbar-nav.active {
//   .nav-link .dropdown-item,
//     .nav-link a,
//     .portfolio-links {
//     color: #5eb9f0;
//   }
// }
// }

.home.bg-soft-primary {
  background: none !important;
}

.home {
  .dropdown-item.dropdown-toggle,
  .nav-item.dropdown .nav-link .dropdown-item {
    color: white;
  }
}

.home .navbar-clone {
  .dropdown-item.dropdown-toggle,
  .nav-item.dropdown .nav-link .dropdown-item {
    color: black;
    &:hover {
      color: #5eb9f0;
    }
  }
  @media screen and (max-width: 991px) {
    .dropdown-item.dropdown-toggle {
      color: white;
      &:hover {
        color: #5eb9f0;
      }
    }
  }
}

.dropdown-item.dropdown-toggle {
  // margin-top: 5px;
  padding: 0 !important;
}
@media screen and (min-width: 992px) {
  .dropdown-item.dropdown-toggle {
    // margin-top: 5px;
    padding: 5px 20px !important;
  }
  // .header .navbar-clone .dropdown-item.dropdown-toggle {
  //   color:black;
  // }
}
.bg-soft-primary {
  background: none !important;
  // .nav-link {
  //   color: black;
  // }
}
@media screen and (min-width: 992px) {
  .dropdown-item {
    color: black;
    &:hover {
      color: #5eb9f0;
    }
  }
}
// .navbar-light .nav-link {
//   color: black;
// }

// .navbar-light.fixed .nav-link:hover {
//   color: #5eb9f0
// }
//footer nav
.nav-footer {
  li {
    display: none !important;
    @media screen and (min-width: 992px) {
      display: block !important;
    }
    list-style-type: none; // remove bullet points
    width: 33%;
  }
  &::marker {
    display: none;
  }
}
.list-unstyled li {
  list-style-type: none; // remove bullet points
}
.form-floating > .form-control:-webkit-autofill ~ label {
  text-align: left;
}
label[for='seo-check'] {
  text-align: left;
}
#loading {
  display: none;
}
.more.hover {
  margin-bottom: 20px;
}
.panel {
  height: 10px;
  position: static;
  top: 0;

  &:not(#section-cta) {
    padding-top: 74px;
  }
}
.video-hero.glossary:first-child {
  clip-path: border-box;
  position: relative;
}

.video-hero.glossary:first-child::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: linear-gradient(to bottom, transparent, black);
}

// .content-scroll::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 10rem;
//   z-index: 9;
//   background: linear-gradient(to bottom, black, transparent);
// }
.video-hero {
  width: 100%;
  min-height: 115vh;
  background: #000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 5rem 0;
  box-sizing: border-box;
  align-items: center;
  @media screen and (min-width: 768px) {
    clip-path: polygon(0 5%, 100% 0, 100% 90%, 0 95%);
  }
  &:first-child {
    clip-path: polygon(0 0%, 100% 0, 100% 80%, 0 100%);
  }
  @media screen and (max-width: 900px) {
    @media screen and (orientation: landscape) {
      padding-top: 20px;
      h1 {
        padding-top: 30vh;
      }
      section > div {
        // margin-bottom: 250px;
      }
    }
  }
}
.video-hero h1 {
  order: 1;
  color: #999;
  opacity: 1;
}
.video-hero .row {
  order: 2;
  opacity: 1;
}
.video-hero .row p,
.video-hero .row h3 {
  color: #999;
}
.video-hero__content,
.video-hero h2 {
  order: 0;
  opacity: 1;
  animation: fadein 1.5s;
  animation-iteration-count: 1;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
}
.video-hero h2 {
  transform: scale(1.2);
  position: relative;
  background: #999;
  text-align: center;
  margin: 0 auto;
  overflow: hidden;
  text-transform: uppercase;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  clip-path: inset(1px);
}
.video-hero.glossary h2 {
  transform: none !important;
}

#point-process.video-hero h2 {
  transform: none;
}
#point-process.video-hero .hero_text p {
  margin-bottom: 0;
}
#point-process.video-hero video {
  // width: 200%;
  width: 120%;
  height: 160%;
}

.video-hero > div {
  max-width: 100%;
}
.video-hero h2 .hero_text {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  mix-blend-mode: darken;
  z-index: 1;
  background-color: #000;
  color: #999;
  overflow: hidden;
}
.video-hero h2 .hero_text p,
.video-hero h2 .hero_text span {
  background-color: #000;
  line-height: 1.1;
  font-weight: 900;
  font-size: 4rem;
  @media screen and (min-width: 768px) {
    font-size: 6rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 8rem;
  }
}
//  long title
.video-hero.title-long h2 .hero_text p {
  background-color: #000;
  line-height: 1.1;
  font-weight: 900;
  font-size: 2rem;
  @media screen and (min-width: 768px) {
    font-size: 2rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 4rem;
  }
}
.video-hero.title-long h2 .hero_text span {
  background-color: #000;
  line-height: 1.1;
  font-weight: 900;
  font-size: 1rem;
  @media screen and (min-width: 768px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2rem;
  }
}

.video-hero h2 video {
  position: absolute;
  top: 0;
  left: -20%;
  width: 210%;
  height: 100%;
  object-fit: cover;
  filter: brightness(200%);
}
.bg-white .video-hero video {
  top: 20px;
  width: 190%;
}
.bg-white .video-hero .row p {
  color: #60697b;
}
.bg-white .video-hero,
.bg-white .video-hero h1,
.bg-white .video-hero h2,
.bg-white .video-hero h2 .hero_text,
.bg-white .video-hero h2 .hero_text span,
.bg-white .video-hero h2 .hero_text p {
  background-color: #fff !important;
  color: black;
}

.bg-white .video-hero h2 .hero_text p {
  margin: 0;
  line-height: 8rem;
  margin-top: -1.5rem auto 0;

  @media screen and (min-width: 768px) {
    line-height: 10rem;
    margin-top: -2rem;
  }
  @media screen and (min-width: 992px) {
    line-height: 12.5rem;
    margin-top: -1rem;
  }
}
.bg-white .video-hero h2 .hero_text {
  mix-blend-mode: lighten !important;
  background-color: #000 !important;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

.video-hero svg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0; // needed for FF, Safari, Edge
  height: 100%;
}
.video-hero .vid {
  margin-top: -30px;
}
.video-hero svg text {
  font-family: Avenir, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 13px;
}
.video-hero svg rect {
  fill: white;
}
.video-hero svg > rect {
  -webkit-mask: url(#mask);
  mask: url(#mask);
}

.home #website-test {
  padding-top: 20vh;
  min-height: 100vh;
}

.blockquote-details .info {
  padding-top: 0.5rem;
}

//typography
h1,
h2,
h3,
h4,
h5 {
  text-transform: capitalize;
}
.text-light h2,
.text-light h3 {
  @extend .text-light;
}
.items-bg-light .card-body p {
  @extend .text-dark;
}
.items-bg-light .border-bottom-1 {
  border-bottom: none;
}
p {
  max-width: 666px;
}
//utilities
.table-5 {
  th,
  td {
    padding: 0.25rem;
  }
}
.mw {
  max-width: 666px;
}
.mw-30 {
  max-width: 540px;
}
.bg-overlay-image {
  position: absolute;
  top: -10px;
  right: 30px;
}
.text-initial {
  text-transform: initial;
}
ul ul li {
  list-style-type: disc;
}
.h-min-100vh {
  min-height: 100vh;
}
.h-100vh {
  height: 100vh;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.position-relative {
  position: relative !important;
}
.object-fit-cover {
  object-fit: cover;
}
.cursor-pointer {
  cursor: pointer;
}
.fade-out {
  opacity: 0 !important;
  transition: opacity 1s linear;
  max-height: 0;
  transition: max-height 1s linear, opacity 1s linear;
}
.fade-in {
  opacity: 1 !important;
  height: auto !important;
  max-height: 10000px !important;
  transition: max-height 1s linear, opacity 1s linear;
}
#message,
#analysis {
  opacity: 0;
  max-height: 0;
  transition: max-height 1s linear, opacity 1s linear;
}
#message,
.fade-in {
  transition-delay: 1s !important;
}
#message p {
  text-align: center;
  margin: auto;
}
table * {
  vertical-align: top;
  border: none;
}
table td,
th {
  display: block;
  border-bottom: none !important;
  line-height: 1.2;
  padding: 0 10px 0 0;
  padding-top: 10px;
}
table td div {
  padding: 0 !important;
}
.mobile {
  display: initial;
}
table tr {
  border-bottom: solid 1px #ccc !important;
  padding: 10px 0;
  position: relative;
}
table tr:nth-child(odd).border-bottom-1,
.border-bottom-1 {
  border-bottom: solid 1px #ccc !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
}
table tr:nth-child(odd) {
  border-bottom: none !important;
}

table td.desktop {
  white-space: nowrap;
}
table th:first-child,
table td:first-child {
  text-align: left !important;
}
table td:first-child {
  float: left;
}
table th:last-child,
table td:last-child {
  padding-right: 20px;
}
table td,
th {
  display: table-cell;
  vertical-align: top;
}
table td:first-child {
  float: none;
}

.accordion-collapse {
  max-width: 600px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: navajowhite;
  border-radius: 0px;
  padding-bottom: 0px;
}
table td div.accordion-collapse {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.accordion-header {
  td {
    position: relative;
  }
}
h3.accordion-button {
  display: initial !important;
}
div.accordion-button:after {
  width: 100%;
  height: 100%;
  position: absolute;
  min-height: 15px;
  display: block;
  z-index: 9;
  top: 0;
  left: auto;
  right: 0;
}
#accordionExample div.accordion-button.collapsed:after {
  content: '\e92d';
}
#accordionExample div.accordion-button:after {
  content: '\e932';
  font-family: 'Unicons';
  position: absolute;
  left: auto;
  right: 0;
  top: 12px;
  font-size: 1.15rem;
  font-weight: normal;
  width: auto;
  margin-top: -0.25rem;
  margin-left: -0.3rem;
  display: inline-block;
  color: #5eb9f0;
}
@media screen and (min-width: 768px) {
  .mobile {
    display: none;
  }
}

input,
textarea,
select {
  -webkit-appearance: auto !important;
  appearance: auto !important;
}
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
.form-check-input {
  margin-top: -1px;
}

//portfolio

.hero-portfolio div {
  left: 0;
  top: 0;
  min-height: 100vh;
  width: 100%;
  position: absolute !important;
  z-index: 9;
  top: 0;
  right: 0;
  &:before,
  &:after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  &:before {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 75%, rgba(9, 36, 97, 1) 100%);
  }
  &:after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 75%, rgba(9, 36, 97, 1) 100%);
  }
}
.hero-portfolio {
  max-width: 1500px;
  margin: 0 auto;
}
.color {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 110%;
  // background-color: #214802  ;
}
.content-wrapper {
  z-index: 8;
}

.blue {
  .color {
    background-color: rgba(9, 36, 97, 1);
  }
  .hero-portfolio div {
    &:before {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 75%, rgba(9, 36, 97, 1) 100%);
    }
    &:after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 75%, rgba(9, 36, 97, 1) 100%);
    }
  }
}

.lightblue {
  .color {
    background-color: rgba(55, 105, 176, 1);
  }
  .hero-portfolio div {
    &:before {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 75%, rgba(55, 105, 176, 1) 100%);
    }
    &:after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 75%, rgba(55, 105, 176, 1) 100%);
    }
  }
}
.skyblue {
  .color {
    background-color: rgba(145, 173, 206, 1);
  }
  .hero-portfolio div {
    &:before {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 75%, rgba(145, 173, 206, 1) 100%);
    }
    &:after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 75%, rgba(145, 173, 206, 1) 100%);
    }
  }
}
.none {
  .color {
    background-color: transparent !important;
  }
  .hero-portfolio div {
    &:before {
      background: transparent !important;
    }
    &:after {
      background: transparent !important;
    }
  }
}

.green {
  .color {
    background-color: rgba(55, 102, 0, 1);
  }
  .hero-portfolio div {
    &:before {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 75%, rgba(55, 102, 0, 1) 100%);
    }
    &:after {
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 75%, rgba(55, 102, 0, 1) 100%);
    }
  }
}
.cardboard {
  .image-wrapper,
  .color,
  .hero-portfolio img {
    max-height: 500px;
  }
  .color {
    background-image: url('/img/clients/cardboard.png');
  }
  .hero-portfolio img {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  .hero-portfolio div {
    &:before {
      background: transparent;
    }
    &:after {
      background: transparent;
    }
  }
}

.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
}

.wrapper.bg-light .carousel-container .swiper-pagination {
  top: auto;
  bottom: 10px !important;
}

.client-logos > div {
  &:first-child {
    img {
      transform: scale(1.6);
    }
  }
  &:nth-child(2) {
    img {
      transform: scale(1.6);
    }
  }
  &:nth-child(4) {
    img {
      transform: scale(0.75);
    }
  }
  &:nth-child(7) {
    img {
      transform: scale(1.9);
    }
  }
}

.new-keywords {
  .keyword-list {
    display: none;
    &.reveal {
      display: block;
    }
  }
  h3 {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  ol {
    margin: 0;
    padding: 0;
  }
  button {
    background-color: transparent;
    padding: 0 5px;
    border: none;
    color: white;
    // float: left;
  }
  .image button {
    background-color: transparent;
    padding: 20px;
    border: none;
    // float: left;
  }
}

.hide-home {
  li:first-child {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  .nav-link.navlinkmtlg {
    margin-top: 0.25rem;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0.25rem;
  }
}
button.hamburger {
  color: #333;
}
.home {
  button.hamburger {
    color: #fff;
  }
}

.home .navbar-clone,
.navbar-clone {
  button.hamburger {
    color: #333;
  }
}
//***
.glossary-component {
  #accordion-14
   {
    display: none !important;
  }
   
  a {
    white-space: wrap;
    text-align: left;
    display: inline;
  }
}

.accordion-item {
  background-color: #000;
}

.video-hero-grid {
  min-height: 100vh;
  display: grid;
  @media screen and (min-width: 768px) {
    grid-template-columns: 250px minmax(auto, 700px);
  }
  justify-content: center; /* Center the grid items horizontally */
  gap: 20px; /* Space between columns */
  padding-top: 15px;
  margin-bottom: 15px;
  overflow: hidden;
  width: 100%;
}

.glossary-menu:hover .glossary-menu {
  display: block;
  width: 100%;
  opacity: 1;
}


::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background: transparent;
}