/*!
Theme Name: Sandbox - Modern & Multipurpose Nextjs Template
Theme URI:	
Version:	1.2.0
Author:		ui-lib
*/

// Bootstrap Functions
@import '../../../node_modules/bootstrap/scss/functions';

// Theme colors
@import 'theme-colors';

// User variables
@import 'user-variables';

// Theme variables
@import 'variables';

// Bootstrap configuration
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/variables-dark';
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/utilities';

// Theme configuration
@import 'theme/functions';
@import 'theme/mixins';
@import 'theme/utilities';
@import 'theme/root';

// Bootstrap layout & components
@import 'bootstrap';

// Theme layout & components
@import 'theme/theme';

// User custom styles
@import 'user';
